<template>
  <el-table ref="textsCommentsTable" :data="commentsForModeration"
            style="width: 100%" border
            v-loading="gqlCommentsListPending">
    <el-table-column label="Username" width="150">
      <template #default="scope">
        <span :style="{color: nameApproved(scope.row.author) ? 'green' : 'red'}">
          {{ scope.row.author.approvedName || scope.row.author.pendingName }}
          <div style="padding: 10px;" v-if="nameApproved(scope.row.author)">
            <el-button
              size="mini"
              type="warning"
              @click="onUserModeration(scope.row.author.id, 3)">Decline name</el-button>
          </div>

          <div style="padding: 10px;" v-else>
            <el-button
              size="mini"
              type="success"
              @click="onUserModeration(scope.row.author.id, 2)">Approve name</el-button>
          </div>
        </span>
      </template>
    </el-table-column>
    <el-table-column property="country" label="Country" width="80"></el-table-column>
    <el-table-column property="content" label="Content" width=""></el-table-column>
    <el-table-column property="moderationStatusId" label="Status" width="100">
      <template #default="scope">
        <ModerationStatus :status="scope.row.moderationStatusId" />
      </template>
    </el-table-column>
    <el-table-column label="Operations" width="200">
      <template #default="scope">
        <div style="padding: 10px;">
          <el-button
            size="mini"
            type="success"
            @click="onModeration(scope.row.id, 2)">Approve</el-button>
        </div>

        <div style="padding: 10px;">
          <el-button
            size="mini"
            type="warning"
            @click="onModeration(scope.row.id, 3)">Decline</el-button>
        </div>
        <div style="padding: 10px;">
          <el-button
            size="mini"
            type="danger"
            @click="onModeration(scope.row.id, 4)">Delete</el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    layout="prev, pager, next"
    hide-on-single-page
    :current-page="commentsPagination.currentPage"
    :page-size="commentsPagination.perPage"
    :total="commentsPagination.total"
    @current-change="onTextsPageChange">
  </el-pagination>
</template>

<script>
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import { gql } from 'graphql-tag';
import ModerationStatus from './ModerationStatus.vue';

export default {
  components: { ModerationStatus },
  props: ['text-uuid', 'page'],

  name: 'TextComments',

  setup(props) {
    const {
      result: gqlCommentsList,
      loading: gqlCommentsListPending,
      error: gqlCommentsListError,
      fetchMore: gqlCommentsFetchMore,
    } = useQuery(gql`
      query ($textUuid: String!, $page: Int!) {
        commentsForText(textUuid: $textUuid, page: { page: $page }) {
          total
          perPage
          currentPage
          lastPage

          data {
            id

            author {
              id
              approvedName
              pendingName
              moderationStatusId
            }

            country
            content

            moderationStatusId

            createdAt
            updatedAt
            deletedAt
          }
        }
      }
    `, {
      textUuid: props.textUuid,
      page: 1,
    });

    const commentsForModeration = useResult(gqlCommentsList, [],
      (result) => result.commentsForText.data);

    const commentsPagination = useResult(gqlCommentsList, {},
      (result) => result.commentsForText);

    const commentsFetchMore = (textUuid, page) => {
      gqlCommentsFetchMore({
        variables: {
          textUuid,
          page,
        },

        // TODO: `updateQuery` is deprecated, remove it
        updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
      });
    };

    const {
      mutate: gqlModeration,
      loading: gqlModerationPending,
      error: gqlModerationError,
    } = useMutation(gql`
      mutation ($commentId: ID!, $status: Int!) {
        commentModeration(commentId: $commentId, status: $status)
      }
    `);

    const {
      mutate: gqlUserModeration,
      loading: gqlUserModerationPending,
      error: gqlUserModerationError,
    } = useMutation(gql`
      mutation ($userId: ID!, $status: Int!) {
        userModeration(userId: $userId, status: $status)
      }
    `);

    return {
      gqlCommentsList,
      gqlCommentsListPending,
      gqlCommentsListError,
      gqlCommentsFetchMore,
      commentsForModeration,
      commentsPagination,
      commentsFetchMore,

      gqlModeration,
      gqlModerationPending,
      gqlModerationError,

      gqlUserModeration,
      gqlUserModerationPending,
      gqlUserModerationError,
    };
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    onTextsPageChange(newPage) {
      this.currentPage = newPage;
      this.commentsFetchMore(this.textUuid, this.currentPage);
    },

    async onModeration(commentId, status) {
      const result = await this.gqlModeration({
        commentId,
        status,
      });

      if (result.data.commentModeration) {
        this.onTextsPageChange(this.currentPage);
      }
    },

    async onUserModeration(userId, status) {
      const result = await this.gqlUserModeration({
        userId,
        status,
      });

      if (result.data.userModeration) {
        this.onTextsPageChange(this.currentPage);
      }
    },

    nameApproved(author) {
      return author.moderationStatusId === 2;
    },
  },

  watch: {
    textUuid() {
      this.currentPage = 1;
      this.onTextsPageChange(this.currentPage);
    },
  },
};
</script>

<style scoped>

</style>
