<template>
  <div>NOT FOUND</div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
</style>
