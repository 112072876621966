import 'reset-css/reset.css';
import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/lib/theme-chalk/display.css';

import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import { ApolloClients } from '@vue/apollo-composable';

import App from './App.vue';
import router from './router';
import store from './store';
import apolloClients from './apollo';

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .provide(ApolloClients, apolloClients)
  .mount('#app');
