import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { LOGIN, LOGOUT } from './mutations';

export default createStore({
  plugins: [
    createPersistedState({
      paths: ['token'],
    }),
  ],
  state: {
    token: null,
  },
  mutations: {
    [LOGIN](state, token) {
      state.token = token;
    },

    [LOGOUT](state) {
      state.token = null;
    },
  },
  actions: {},
  modules: {},
});
