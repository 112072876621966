import {
  ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import store from '@/store';

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${window.location.origin}/gql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const authToken = store.state.token;

    const h = headers || {};
    if (authToken) {
      h.authorization = `Bearer ${authToken}`;
    }

    return { headers: h };
  });

  return forward(operation);
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
});

export default {
  default: apolloClient,
};
