<template>
  <span>{{ humanized() }}</span>
</template>

<script>
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';

export default {
  name: 'HumanTimestamp',
  props: ['ts'],

  methods: {
    humanized() {
      const t = DateTime.fromSeconds(this.ts || 0);
      const diff = t.toSeconds() - DateTime.now().toSeconds();
      if (diff < 24 * 60 * 60) {
        return humanizeDuration(diff * 1000, { largest: 1 });
      }
      return t.toLocal().toLocaleString(DateTime.DATETIME_SHORT);
    },
  },
};
</script>

<style></style>
