<template>
  <span>{{ names[status] || 'unknown' }}</span>
</template>

<script>
const MAPPING = {
  0: 'pending',
  1: 'published',
  2: 'approved',
  3: 'declined',
  4: 'deleted',
};

export default {
  name: 'ModerationStatus',
  props: ['status'],

  data() {
    return {
      names: MAPPING,
    };
  },
};
</script>

<style></style>
