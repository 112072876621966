<template>
  <el-table ref="textsListTable" :data="textsForModeration"
            highlight-current-row @current-change="onSelectedChange"
            style="width: 100%" border
            v-loading="gqlTextsListPending">
    <el-table-column property="title" label="Header"></el-table-column>
    <!--
    <el-table-column property="country" label="Country" width="100"></el-table-column>
    -->
    <el-table-column label="Not reviewed since" width="180">
      <template #default="scope">
        <HumanTimestamp :ts="scope.row.lastCommentedAt" />
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    layout="prev, pager, next"
    hide-on-single-page
    :current-page="textsPagination.currentPage"
    :page-size="textsPagination.perPage"
    :total="textsPagination.total"
    @current-change="onTextsPageChange">
  </el-pagination>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import { gql } from 'graphql-tag';
import HumanTimestamp from './HumanTimestamp.vue';

export default {
  components: { HumanTimestamp },
  props: ['page', 'status'],
  emits: ['page-changed', 'text-selected'],

  name: 'TextsList',

  setup(props) {
    const {
      result: gqlTextsList,
      loading: gqlTextsListPending,
      error: gqlTextsListError,
      fetchMore: gqlTextsFetchMore,
    } = useQuery(gql`
    query ($status: Int!, $page: Int!) {
      textsForModeration(filterModStatus: $status, page: { page: $page }) {
        total
        perPage
        currentPage
        lastPage

        data {
          uuid
          lastCommentedAt
          lang
          title
          content
        }
      }
    }
  `, { page: props.page || 1, status: props.status || 0 });

    const textsForModeration = useResult(gqlTextsList, [],
      (result) => result.textsForModeration.data);

    const textsPagination = useResult(gqlTextsList, {}, (result) => result.textsForModeration);

    const textsFetchMore = (status, page) => {
      gqlTextsFetchMore({
        variables: {
          status,
          page,
        },

        // TODO: `updateQuery` is deprecated, remove it
        updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
      });
    };

    return {
      gqlTextsList,
      gqlTextsListPending,
      gqlTextsListError,
      textsForModeration,
      textsPagination,
      textsFetchMore,
    };
  },

  mounted() {
    this.onTextsPageChange(this.$route.query.page || 1);
  },

  data() {
    return {
      currentPage: this.$route.query.page || 1,
    };
  },

  methods: {
    onTextsPageChange(newPage) {
      this.currentPage = newPage;
      this.textsFetchMore(this.status, this.currentPage);

      this.$emit('page-changed', this.currentPage);
    },

    onSelectedChange(selected) {
      this.$emit('text-selected', {
        textUuid: selected ? selected.uuid : null,
        text: selected,
      });
    },
  },

  watch: {
    status() {
      this.onTextsPageChange(this.currentPage);
    },
  },
};
</script>

<style scoped>

</style>
