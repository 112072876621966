<template>
  <!--

    TODO: el-dropdown is somehow broken: no dropdown & no reactive updates

  <el-dropdown>
    <span class="el-dropdown-link">
      <span>CURRENT USER</span>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item>Profile</el-dropdown-item>
        <el-dropdown-item divided @click="onLogout">Logout</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  -->
  <div>
    <span @click="onProfile">
      {{ gqlCurrentUser ? gqlCurrentUser.currentUser.name : '' }}
    </span> |
    <span @click="onLogout">Logout</span>
  </div>
</template>

<script>
import { useQuery, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { mapMutations } from 'vuex';
import { LOGOUT } from '@/store/mutations';

export default {
  name: 'UserMenu',

  setup() {
    const { mutate: gqlLogout, loading: gqlLogoutPending, error: gqlLogoutError } = useMutation(gql`
      mutation doLogout {
        logout
      }
    `);

    const { result: gqlCurrentUser, loading: gqlCurrentUserPending, error: gqlCurrentUserError } = useQuery(gql`
      query getCurrentUser {
        currentUser {
          name
        }
      }
    `);

    return {
      gqlLogout,
      gqlLogoutPending,
      gqlLogoutError,

      gqlCurrentUser,
      gqlCurrentUserPending,
      gqlCurrentUserError,
    };
  },

  methods: {
    ...mapMutations({
      LOGOUT,
    }),

    onProfile() {
      this.$router.push({ name: 'profile' });
    },

    async onLogout() {
      await this.gqlLogout();

      this.LOGOUT();
      await this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style>
  .el-dropdown-link {
    cursor: pointer; color: #409eff;
  }
</style>
