<template>
  <el-table ref="textsListTable" :data="texts"
            highlight-current-row @current-change="onSelectedChange"
            style="width: 100%" border
            v-loading="loading">
    <el-table-column property="title" label="Header">
      <template #default="scope">
        <highlighter :words="[query]" :text="scope.row.title"></highlighter>
      </template>
    </el-table-column>
    <!--
    <el-table-column property="country" label="Country" width="100"></el-table-column>
    -->
  </el-table>

  <el-pagination
    layout="prev, pager, next"
    hide-on-single-page
    :current-page="pagination.currentPage"
    :page-size="pagination.perPage"
    :total="pagination.total"
    @current-change="onTextsPageChange">
  </el-pagination>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import Highlighter from '@/components/Highlighter.vue';

export default {
  name: 'MatchedTexts',
  props: ['query', 'page'],

  components: { Highlighter },
  emits: ['page-changed', 'text-selected'],

  setup(props) {
    const { result, loading, fetchMore } = useQuery(gql`
      query ($textQuery: String!, $page: Int!) {
        searchTexts(query: $textQuery, page: { page: $page }) {
          total
          perPage
          currentPage
          lastPage

          data {
            uuid
            lastCommentedAt
            lang
            title
            content
          }
        }
      }
    `, () => ({
      textQuery: props.query,
      page: props.page || 1,
    }));

    const texts = useResult(result, [], (x) => x.searchTexts.data);
    const pagination = useResult(result, [], (x) => {
      const res = { ...x.searchTexts };
      delete res.data;
      return res;
    });

    return {
      texts,
      pagination,
      loading,
      fetchMore,
    };
  },

  methods: {
    onSelectedChange(selected) {
      this.$emit('text-selected', {
        textUuid: selected ? selected.uuid : null,
        text: selected,
      });
    },

    onTextsPageChange(newPage) {
      this.fetchMore({
        variables: {
          textQuery: this.query,
          page: this.page || 1,
        },
      });

      this.$emit('page-changed', newPage);
    },
  },
};
</script>
