<template>
  <el-menu
    class="el-menu-vertical-demo"
    style="height: 100%"
    router
    :default-active="activeLink"
  >
    <el-menu-item index="/pending_comments" :route="{name: 'pending_comments'}">
      <i class="el-icon-s-comment"></i>
      <span>Pending Comments</span>
    </el-menu-item>
    <el-menu-item index="/browse_comments" :route="{name: 'browse_comments'}">
      <i class="el-icon-s-comment"></i>
      <span>Browse Comments</span>
    </el-menu-item>
    <el-menu-item index="/users" :route="{name: 'users'}">
      <i class="el-icon-user-solid"></i>
      <span>Users</span>
    </el-menu-item>
    <!--
    <el-menu-item index="/admins" :route="{name: 'admins'}">
      <i class="el-icon-document"></i>
      <span>Admins</span>
    </el-menu-item>
    -->
  </el-menu>
</template>

<script>
export default {
  name: 'SidebarMenu',

  data() {
    return {
      activeLink: null,
    };
  },

  mounted() {
    this.activeLink = this.$route.path;
  },

  watch: {
    $route(to) {
      this.activeLink = to.path;
    },
  },
};
</script>

<style scoped>

</style>
