<template>
  <el-table ref="usersTable" :data="usersForModeration"
            style="width: 100%"
            v-loading="gqlUsersListPending">
    <el-table-column property="approvedName" label="Approved Username" width="350">
    </el-table-column>
    <el-table-column property="pendingName" label="Pending Username" width="350"></el-table-column>

    <el-table-column property="moderationStatusId" label="Status" width="100">
      <template #default="scope">
        <ModerationStatus :status="scope.row.moderationStatusId" />
      </template>
    </el-table-column>

    <el-table-column label="Operations">
      <template #default="scope">
        <el-button
          size="mini"
          type="success"
          @click="onModeration(scope.row.id, 2)">Approve</el-button>
        <el-button
          size="mini"
          type="warning"
          @click="onModeration(scope.row.id, 3)">Decline</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
    layout="prev, pager, next"
    hide-on-single-page
    :current-page="usersPagination.currentPage"
    :page-size="usersPagination.perPage"
    :total="usersPagination.total"
    @current-change="onUsersPageChange">
  </el-pagination>
</template>

<script>
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import { gql } from 'graphql-tag';
import ModerationStatus from './ModerationStatus.vue';

export default {
  name: 'UsersList',
  components: { ModerationStatus },
  props: ['page'],
  emits: ['page-change'],

  setup(props) {
    const {
      result: gqlUsersList,
      loading: gqlUsersListPending,
      error: gqlUsersListError,
      fetchMore: gqlUsersFetchMore,
    } = useQuery(gql`
    query ($page: Int!) {
      usersForModeration(page: { page: $page }) {
        total
        perPage
        currentPage
        lastPage

        data {
          id
          approvedName
          pendingName
          moderationStatusId
        }
      }
    }
    `, { page: props.page || 1 });

    const usersForModeration = useResult(gqlUsersList, [],
      (result) => result.usersForModeration.data);

    const usersPagination = useResult(gqlUsersList, {}, (result) => result.usersForModeration);

    const usersFetchMore = (page) => {
      gqlUsersFetchMore({
        variables: {
          page,
        },

        // TODO: `updateQuery` is deprecated, remove it
        updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
      });
    };

    const {
      mutate: gqlUserModeration,
      loading: gqlUserModerationPending,
      error: gqlUserModerationError,
    } = useMutation(gql`
      mutation ($userId: ID!, $status: Int!) {
        userModeration(userId: $userId, status: $status)
      }
    `);

    return {
      gqlUsersList,
      gqlUsersListPending,
      gqlUsersListError,
      usersForModeration,
      usersPagination,
      usersFetchMore,

      gqlUserModeration,
      gqlUserModerationPending,
      gqlUserModerationError,
    };
  },

  mounted() {
    this.onUsersPageChange(this.$route.query.page || 1);
  },

  data() {
    return {
      currentPage: this.$route.query.page || 1,
    };
  },

  methods: {
    onUsersPageChange(newPage) {
      this.currentPage = newPage;
      this.usersFetchMore(this.currentPage);

      this.$emit('page-changed', this.currentPage);
    },

    async onModeration(userId, status) {
      const result = await this.gqlUserModeration({
        userId,
        status,
      });

      if (result.data.userModeration) {
        this.onUsersPageChange(this.currentPage);
      }
    },
  },

  watch: {
    status(v) {
      this.onUsersPageChange(v, this.currentPage);
    },
  },
};
</script>

<style></style>
