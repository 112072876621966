import { createRouter, createWebHashHistory } from 'vue-router';
import NotFound from '@/pages/NotFound.vue';
import Login from '@/pages/Login.vue';
import TextsListPage from '@/pages/TextsListPage.vue';
import BrowseTextsPage from '@/pages/BrowseTextsPage.vue';
import UserListPage from '@/pages/UsersListPage.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: '/pending_comments',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresNotAuth: true,
    },
  },
  {
    path: '/pending_comments',
    name: 'pending_comments',
    component: TextsListPage,
    props: {
      status: 0,
    },
    meta: {
      requiresAuth: true,
    },
  },
  /*
  {
    path: '/published_comments',
    name: 'published_comments',
    component: TextsListPage,
    props: {
      status: 1,
    },
    meta: {
      requiresAuth: true,
    },
  },
  */
  {
    path: '/browse_comments',
    name: 'browse_comments',
    component: BrowseTextsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: UserListPage,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/admins',
  //   name: 'admins',
  //   component: NotFound,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.state.token) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else if (to.matched.some((route) => route.meta.requiresNotAuth)) {
    if (store.state.token) {
      next({ name: 'default' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
