<template>
  <el-row style="margin: 5px 5px 5px 15px;">
    <el-col :span="2" class="vertical-align">
      <span>Search query:</span></el-col>
    <el-col :span="22" style="padding: 10px;">
      <el-input
        placeholder="Search query"
        v-model="typedQuery"
        class="w-50 m-2"></el-input>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="8" style="padding: 10px;">
      <div style="text-align: center; padding: 10px;">Texts</div>
      <MatchedTexts
        :query="$route.query.query"
        :page="$route.query.page"
        @text-selected="onTextSelected"
        @page-changed="onPageChange"
      ></MatchedTexts>
    </el-col>

    <el-col :span="16" style="padding: 10px;">
      <div style="text-align: center; padding: 10px;">Comments</div>

      <el-collapse v-model="textSelected"
                   v-if="textUuid != null"
                   style="margin-bottom: 20px">
        <el-collapse-item title="Text content" name="1">
          <highlighter
            :words="[$route.query.query]"
            :text="text.content"></highlighter>
        </el-collapse-item>
      </el-collapse>

      <TextComments :text-uuid="textUuid" :page="1" v-if="textUuid" />
      <el-empty description="Select text to view comments" v-else></el-empty>
    </el-col>
  </el-row>
</template>

<script>
// import TextsList from '../components/TextsList.vue';
// import TextComments from '../components/TextComments.vue';
import { debounce } from 'vue-debounce';
import Highlighter from '../components/Highlighter.vue';
import MatchedTexts from '../components/MatchedTexts.vue';
import TextComments from '../components/TextComments.vue';

export default {
  name: 'BrowseTextsPage',

  components: { MatchedTexts, TextComments, Highlighter },

  data() {
    return {
      typedQuery: '',
      textUuid: null,
    };
  },

  watch: {
    typedQuery: 'pushQueryToURL',
  },

  methods: {
    // eslint-disable-next-line func-names
    pushQueryToURL: debounce(function (value) {
      this.$router.push({ query: { query: value } });
    }, '500ms'),

    onTextSelected({ textUuid, text }) {
      this.textUuid = textUuid;
      this.text = text;
    },

    onPageChange(newPage) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });
    },
  },
};
</script>

<style scoped>
.vertical-align {
  display: flex;
  align-items: center;
}
</style>
