<template>
  <AuthGuard>
    <router-view/>
  </AuthGuard>
</template>

<style lang="scss">
  * {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
  }

  body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    color: #2c3e50;
    overflow: hidden;
    height: 100%;
  }
</style>
<script>
import AuthGuard from './components/auth/AuthGuard.vue';

export default {
  components: { AuthGuard },
};
</script>
