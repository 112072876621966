<template>
  <el-row>
    <el-col :span="24" style="padding: 10px;">
      <UsersList :page="$route.query.page" @page-cahnge="onPageChange" />
    </el-col>
  </el-row>
</template>

<script>
import UsersList from '@/components/UsersList.vue';

export default {
  name: 'UsersListPage',
  components: { UsersList },
};
</script>

<style scoped>

</style>
