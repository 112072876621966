<template>
  <el-row :gutter="20"
          style="height: 100%; width: 100%;
           display: flex; flex-direction: column; align-items: center;">
    <el-col>
      <el-card>
        <template #header>
          <div>
            <span>Comments Admin Panel</span>
          </div>
        </template>
        <el-form ref="loginForm"
                 :model="loginForm"
                 :rules="loginFormRules"
                 label-width="100px"
                 v-loading="loginPending">
          <div>
            <el-alert :title="loginError.message"
                      type="error"
                      style="margin-bottom: 20px;"
                      v-if="loginError">
            </el-alert>
          </div>
          <el-form-item label="Login" prop="login">
            <el-input placeholder="" v-model="loginForm.login"></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="loginForm.password" show-password></el-input>
          </el-form-item>
          <div style="display: flex; flex-direction: column">
            <el-button type="primary" @click="onSubmit">Login</el-button>
          </div>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { mapMutations } from 'vuex';
import { LOGIN } from '@/store/mutations';

export default {
  name: 'Login',

  setup() {
    const { mutate: login, loading: loginPending, error: loginError } = useMutation(gql`
      mutation ($login: String!, $password: String!) {
        login(login: { login: $login, password: $password }) {
          token
        }
      }`);

    return {
      login,
      loginPending,
      loginError,
    };
  },

  data() {
    return {
      loginForm: {
        login: '',
        password: '',
      },
      loginFormRules: {
        login: [
          { required: true, message: 'Login required', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Password required', trigger: 'blur' },
        ],
      },
    };
  },

  methods: {
    ...mapMutations({
      LOGIN,
    }),
    onSubmit() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const result = await this.login({
            login: this.loginForm.login,
            password: this.loginForm.password,
          });

          this.LOGIN(result.data.login.token);
          await this.$router.push({ name: 'default' });
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style>

</style>
