<template>
  <el-row>
    <el-col :span="8" style="padding: 10px;">
      <div style="text-align: center; padding: 10px;">Texts</div>
      <TextsList :page="$route.query.page" :status="status"
                 @page-changed="onPageChange"
                 @text-selected="onTextSelected" />
    </el-col>

    <el-col :span="16" style="padding: 10px;">
      <div style="text-align: center; padding: 10px;">Comments</div>

      <el-collapse v-model="textSelected"
                   v-if="textUuid != null"
                   style="margin-bottom: 20px">
        <el-collapse-item title="Text content" name="1">
          <div v-html="text.content"></div>
        </el-collapse-item>
      </el-collapse>

      <TextComments :text-uuid="textUuid" :page="1" v-if="textUuid != null" />
      <el-empty description="Select text to view comments" v-else></el-empty>
    </el-col>
  </el-row>
</template>

<script>
import TextsList from '../components/TextsList.vue';
import TextComments from '../components/TextComments.vue';

export default {
  name: 'TextsListPage',
  props: ['status'],

  components: { TextComments, TextsList },

  data() {
    return {
      textUuid: null,
      text: {},
      textSelected: [],
    };
  },

  methods: {
    onPageChange(newPage) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, page: newPage } });
    },

    onTextSelected({ textUuid, text }) {
      this.textUuid = textUuid;
      this.text = text;
    },
  },
};
</script>

<style scoped>

</style>
