<template>
  <el-container class="layout-outer-container" v-if="authenticated">

    <el-header class="layout-header" style="border-bottom: solid 1px #e6e6e6;">
      <div style="height: 100%; display: flex; flex-direction: row; align-items: center;">
        <span style="flex-grow: 1">Comments Admin Panel</span>
        <UserMenu></UserMenu>
      </div>
    </el-header>

    <el-container class="layout-inner-container">
      <el-aside class="layout-sidebar" width="220px">
        <SidebarMenu></SidebarMenu>
      </el-aside>

      <el-container class="layout-main-container">
        <el-main class="layout-main">
          <el-scrollbar>
            <slot></slot>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>

  </el-container>

  <el-container class="layout-outer-container" v-else>
    <el-main>
      <slot></slot>
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from 'vuex';
import SidebarMenu from '@/components/parts/SidebarMenu.vue';
import UserMenu from '@/components/parts/UserMenu.vue';

export default {
  name: 'AuthGuard',
  components: { UserMenu, SidebarMenu },
  computed: mapState({
    authenticated: (state) => state.token != null,
  }),
};
</script>

<style lang="scss">
.layout-outer-container {
  height: 100%;

  .layout-header {
    /*background-color: #4986c7;*/
    border-bottom: 2px solid #23476c;
    z-index: 100;
  }

  .layout-inner-container {
    height: calc(100% - 60px);

    .layout-sidebar {
      /*border-right: 1px solid #23476c;*/
    }

    .layout-main-container {
        /*position: relative;*/

      .layout-main {
        padding: 0;
      }
    }
  }
}
</style>
