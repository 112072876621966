<template>
  <div v-html="highlightedHtml()"></div>
</template>

<script>
export default {
  name: 'Highlighter',
  props: ['text', 'words'],

  methods: {
    highlightedHtml() {
      let txt = this.text;

      if (!txt) return '';

      // map instead for..of
      this.words.map((word) => {
        // check that word is not inside tag (negative lookbehind)
        // SEE: https://stackoverflow.com/a/960212/434255
        const r = new RegExp(`(?<!<[^>]*)${word}`, 'igm');

        txt = txt.replace(r, (match) => `<span class="highlight">${match}</span>`);
        return '';
      });

      return txt;
    },
  },
};
</script>

<style>
.highlight {
  background-color: yellow;
}
</style>
